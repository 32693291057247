import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from "../../components/image"
import "../../assets/sewer-system-repair.sass"

const SewerRepair = () => (
  <div className="sewer-system-repair-component">
    <SEO title="SEWER SYSTEM REPAIR SERVICES" />
    
    <div className="service-header">
      <h1>SEWER SYSTEM REPAIR SERVICES</h1>
    </div>

    <p>
      Sewer repairs sometimes can’t be avoided, damage can be caused by many factors including invasive tree roots in the sewer lines, causing blockages and cracks.
    </p>
    <p>
      Our team is here to help you with all your Sewer System Repair needs. With top of the line equipment and experience, we can pinpoint the exact location and depth of what’s causing the issue, to minimize costs and damages in case excavation is needed.
    </p>
    <p>
      We will present a quote and if approved we will mark all utility lines and work with the city to obtain the required permits.
    </p>
    <Image filename="excavation.jpg" />
    
  </div>
)

export default SewerRepair
